import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import SettingsPage from './views/settingsPage';
import LoginPage from './views/loginPage';
import ListPage from './views/listPage';
import FormPage from './views/formPage';
import Order from './views/order';
//const history = createHistory();


let packages = [];

for (let i = 0; i < 5; i++) {
    packages.push({
        name: `package[${i}].name`,
        label: `Pakovanje #${i+1}`,
        type: 'text',
        width: {
            desktop: 3,
            mobile: 6
        }
    })

    packages.push({
        name: `package[${i}].mpc`,
        label: `MPC #${i+1}`,
        type: 'text',
        width: {
            desktop: 2,
            mobile: 6
        }
    });

    packages.push({
        name: `package[${i}].vpc`,
        label: `VPC #${i+1}`,
        type: 'text',
        width: {
            desktop: 2,
            mobile: 6
        }
    });

    packages.push({
        name: `package[${i}].imageIndex`,
        label: `Image Index #${i+1}`,
        type: 'text',
        width: {
            desktop: 2,
            mobile: 6
        }
    });
    packages.push({
        name: `package[${i}].sku`,
        label: `SKU #${i+1}`,
        type: 'text',
        width: {
            desktop: 2,
            mobile: 6
        }
    });
    packages.push({
        "type": "offset",
        width: {
            desktop: 12,
            mobile: 12
        }
    });
}


let formPages = [
    {
        title: 'Administratori',
        subtitle: '',
        link: '/admins',
        collection: 'admins',
        multilang: false,
        fields: [
            {
                name: 'username',
                label: 'Username',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'pk',
                label: 'Lozinka',
                type: 'password',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                name: 'userRole',
                label: 'Tip admina',
                type: 'select',
                values: [
                    {
                        name: 'SUPERIOR',
                        value: 'super'
                    }, {
                        name: 'SIMPLE',
                        value: 'simple'
                    }, {
                        name: 'SPECIAL',
                        value: 'special'
                    },

                ],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [

        ]
    },
    {
        title: 'Brendovi',
        subtitle: '',
        link: '/brands',
        collection: 'brands',
        multilang: false,
        fields: [
            {
                name: 'Name',
                label: 'Ime',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Firme',
        subtitle: '',
        link: '/companies',
        collection: 'companies',
        multilang: false,
        fields: [
            {
                name: 'Link',
                label: 'Link',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
        ],
        linkedFields: [

        ]
    },

    {
        title: 'O nama',
        subtitle: '',
        link: '/aboutus',
        collection: 'aboutus',
        multilang: false,
        fields: [
            {
                name: 'companyData',
                label: 'Podaci o firmi',
                type: 'html',
                multilang: false,

                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Content',
                label: 'Tekst',
                type: 'html',
                multilang: false,

                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Newsletter',
        subtitle: 'Configure newsletter',
        link: '/newsletters',
        collection: 'newsletters',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Subtitle',
                label: 'Subtitle',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, 
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            }, {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'html',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Narudžba',
        subtitle: 'Pregled narudžbe',
        link: '/orders',
        collection: 'orders',
        multilang: false,
        fields: [
            {
                type: 'title',
                text: 'Podešavanje fakture'
            },
            {
                name: 'FiscalNumber',
                label: 'Broj fiskalnog računa',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Charged',
                label: 'Datum fakturisanja',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Delivered',
                label: 'Datum isporuke',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'BillMaxDateToPay',
                label: 'Valuta plaćanja',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, {
                name: 'Warehouse',
                label: 'Skladište',
                type: 'select',
                values: [
                    {
                        name: '1 - MAGACIN',
                        value: '1 - MAGACIN'
                    }, {
                        name: '2 - VELEPRODAJA',
                        value: '2 - VELEPRODAJA'
                    }, {
                        name: '3 - MALOPRODAJA',
                        value: '3 - MALOPRODAJA'
                    },

                ],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.JIB',
                label: 'JIB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.PDV',
                label: 'PDV',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.MB',
                label: 'MB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.TaxPayer',
                label: 'Obavezik PDV-a',
                type: 'select',
                values: [
                    {
                        name: 'NE',
                        value: '0'
                    }, {
                        name: 'DA',
                        value: '1'
                    }
                ],
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },


            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

        ],
        linkedFields: [

        ]
    },
    {
        title: 'Proizvod',
        subtitle: 'Dodaj/Izmjeni proizvod',
        link: '/products',
        collection: 'products',
        multilang: false,
        fields: [
            {
                name: 'Alias',
                label: 'Šifra proizvoda',
                generateAlias: true,
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BarCode',
                label: 'Bar kod',
                type: 'text',
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Proizvod je vidljiv u shopu',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'IsPromoted',
                label: 'Izdvojen proizvod',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'OnOffer',
                label: 'Na akciji',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
/*
            {
                name: 'ProductForLegalPersons',
                label: 'Velprodajni prozivod',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
  */
           {
                "type": "spacer"
            },
            {
                name: 'Images',
                label: 'Slike',
                type: 'file-multiple',
                width: {
                    desktop: 9,
                    mobile: 12
                }
            }, {
                "type": "offset",
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Name',
                label: 'Naziv proizvoda',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer',
            },


            {
                name: 'VariationName',
                label: 'Naziv pakovanja',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: `package[0].name`,
                label: `Pakovanje #1`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: `package[0].mpc`,
                label: `MPC #1`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                type: 'spacer',
            },

            {
                name: `package[1].name`,
                label: `Pakovanje #2`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: `package[1].mpc`,
                label: `MPC #2`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                type: 'spacer',
            },


            {
                name: `package[2].name`,
                label: `Pakovanje #3`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: `package[2].mpc`,
                label: `MPC #3`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                type: 'spacer',
            },


            {
                name: `package[3].name`,
                label: `Pakovanje #4`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: `package[3].mpc`,
                label: `MPC #4`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                type: 'spacer',
            },


            {
                name: `package[4].name`,
                label: `Pakovanje #5`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                name: `package[4].mpc`,
                label: `MPC #5`,
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 6
                }
            },

            {
                type: 'spacer',
            },


            {
                name: 'MinOrder',
                label: 'Minimalna narudzba (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevel',
                label: 'Na stanju',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'StockLevelAlert',
                label: 'Upozorenje za magacin (komada)',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                type: 'spacer',
            },
            {
                name: 'Volume',
                label: 'Kubikaža',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                name: 'Weight',
                label: 'Težina',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'QuantityInSet',
                label: 'Komada u setu',
                type: 'text',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                type: 'spacer',
            },
            {
                name: 'ShortDescription',
                label: 'Kratak opis artikla',
                type: 'text-multiline',
                multilang: false,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                name: 'Description',
                label: 'Opis artikla',
                type: 'html',
                multilang: false,

                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer',
            }, {
                name: 'SendNewsletter',
                label: 'Pošalji newsletter',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, {
                type: 'spacer',
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'Breadcrumb',
                label: 'Category',
                type: 'category',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item.Breadcrumb
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                linkedCollection: 'brands',
                name: 'Brand',
                label: 'Brend',
                multiselect: true,
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Name,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }



            },

            {
                linkedCollection: 'units',
                name: 'OrderUnit',
                label: 'Jedinica mjere',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.name,
                                value: item.shortName
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 3,
                    mobile: 12
                }



            },
            {
                linkedCollection: 'attributes',
                name: 'attributes',
                label: 'Atributi',
                type: 'attributes',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Name,
                                value: item.Alias
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },



        ]
    },

    {
        title: 'Korisnik',
        subtitle: 'Dodaj/Izmjeni korisnika',
        link: '/users',
        collection: 'users',
        multilang: false,
        fields: [
            {
                name: 'User.Name',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'User.EMail',
                label: 'Email',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: 'select',
                values: [{ name: 'Fizičko lice', value: 'PhysicalPerson' }, { name: 'Pravno lice', value: 'LegalPerson' }],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                name: 'IP',
                label: 'IP Adresa',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Coordinates',
                label: 'GPS Koordinate',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }, {
                name: 'User.PasswordsHistory[0]',
                label: 'Lozinka',
                type: 'password',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'UserRegion',
                label: 'Regija korisnika',
                type: 'select',
                values: [{ name: '1', value: '1' }, { name: '2', value: '2' }, { name: '3', value: '3' }, { name: '4', value: '4' }],
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'Alias',
                label: 'Redni broj korisnika',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'TimeToPay',
                label: 'Odloženo plaćanje',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                name: 'EnableGPSBlock',
                label: 'Omogući GPS blokiranje',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Coordinates',
                label: 'Mapa',
                type: 'map',
                width: {
                    desktop: 6,
                    mobile: 12
                }

            },
            {
                type: 'spacer'
            },
            {
                name: 'EmailVerified',
                label: 'Verifikovan email',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'IsSalesManager',
                label: 'Komercijalista',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'SubscribeToNewsletter',
                label: 'Prijavljen na newsletter',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },

            {
                type: 'title',
                text: 'Adresa za obračun'
            },

            {
                name: 'BillingAddress.FirstName',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.LastName',
                label: 'Prezime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Company',
                label: 'Firma',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.JIB',
                label: 'JIB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.PDV',
                label: 'PDV',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.MB',
                label: 'MB',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'BillingAddress.TaxPayer',
                label: 'Obavezik PDV-a',
                type: 'select',
                values: [
                    {
                        name: 'NE',
                        value: '0'
                    }, {
                        name: 'DA',
                        value: '1'
                    }
                ],
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },



            {
                type: 'spacer'
            },




            {
                type: 'title',
                text: 'Adresa za isporuku'
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Ime ili naziv firme',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'Prezime ili poslovna jedinica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Street',
                label: 'Ulica i broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            }, {
                name: 'ShippingAddress.City',
                label: 'Grad',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Zipcode',
                label: 'Poštanski broj',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },

            {
                name: 'ShippingAddress.EMail',
                label: 'EMail',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShippingAddress.Phone',
                label: 'Telefon',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },


        ],
        linkedFields: []

    }, {
        title: 'Kategorija',
        subtitle: 'Dodaj/Izmjeni kategoriju',
        link: '/categories',
        collection: 'categories',
        multilang: false,

        fields: [
            {
                name: 'Icon',
                label: 'Icon',
                type: 'file',

                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                name: 'Image',
                label: 'Slika banera na pocetnoj',
                type: 'file',

                width: {
                    desktop: 3,
                    mobile: 12
                }
            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Link',
                label: 'Link',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Position',
                label: 'Pozicija',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Vidljiva',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'OnHome',
                label: 'Na pocetnoj',
                type: 'checkbox',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [
            {
                linkedCollection: 'categories',
                name: 'ParentCategory',
                label: 'Kategorija',
                type: 'select',
                values: [],
                renderItems: (collection) => {
                    return collection.map((item) => {
                        return (
                            {
                                name: item.Alias,
                                value: item._id
                            }
                        )
                    });
                },
                additionalAction: () => {

                },
                width: {
                    desktop: 12,
                    mobile: 12
                }
            }

        ]

    },
    {
        title: 'Atribut',
        subtitle: 'Dodaj/Izmjeni atribut',
        link: '/attributes',
        collection: 'attributes',
        multilang: false,
        fields: [
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Name',
                label: 'Naziv',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Novosti',
        subtitle: 'Dodaj/Izmjeni novost',
        link: '/news',
        collection: 'news',
        multilang: false,
        fields: [
            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                generateAlias: true,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                name: 'Title',
                label: 'Naslov',
                type: 'text',
                multilang: false,

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'ShortDescription',
                label: 'Kratak opis',
                type: 'text-multiline',
                multilang: false,

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'offset',
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'Content',
                label: 'Sadrzaj',
                type: 'html',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                name: 'IsVisible',
                label: 'Aktivna',
                type: 'checkbox',
                width: {
                    desktop: 12,
                    mobile: 12
                }

            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [


        ]

    },
    {
        title: 'Galerija',
        subtitle: 'Izmjeni galeriju',
        link: '/gallery',
        collection: 'gallery',

        fields: [
            {
                name: 'Images',
                label: 'Slike',
                type: 'file-multiple',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

        ],
        linkedFields: [


        ]

    },

    {
        title: 'Product type',
        subtitle: 'Change category',
        link: '/product-types',
        collection: 'productTypes',

        fields: [
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'name',
                label: 'Name',
                type: 'text',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'position',
                label: 'Position',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 1,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },
            {
                name: '_attribute',
                label: 'Select attribute to edit',
                type: 'select',
                values: [],
                additionalAction: (scope, value) => {
                    let fields = scope.state.fields;
                    let newFields = [];

                    let found = false;
                    for (let i = 0; i < fields.length; i++) {

                        if ((fields[i].type === 'title' && !found) || i === fields.length - 1) {

                            if (i === fields.length - 1) {
                                newFields.push(fields[i]);
                            }

                            newFields.push({
                                type: 'title',
                                text: value
                            }
                            );


                            newFields.push({
                                name: 'attributes.' + value + ".alias",
                                label: 'Alias',
                                type: 'text',
                                multilang: false,
                                width: {
                                    desktop: 6,
                                    mobile: 12
                                }
                            }
                            );

                            newFields.push({
                                name: 'attributes.' + value + ".name",
                                label: 'Name',
                                type: 'text',

                                width: {
                                    desktop: 6,
                                    mobile: 12
                                }
                            }
                            );

                            for (let j = 0; j < scope.state.initialValues.attributes[value].values.length; j++) {

                                newFields.push({
                                    name: 'attributes.' + value + ".values[" + j + "].value",
                                    label: 'Item[' + j + "] Value",
                                    type: 'text',
                                    multilang: false,
                                    width: {
                                        desktop: 3,
                                        mobile: 12
                                    }
                                }

                                );

                                newFields.push({
                                    name: 'attributes.' + value + ".values[" + j + "].name",
                                    label: 'Item[' + j + "] Name",
                                    type: 'text',

                                    width: {
                                        desktop: 3,
                                        mobile: 12
                                    }
                                }

                                );

                                newFields.push({
                                    type: 'offset',
                                    width: {
                                        desktop: 12,
                                        mobile: 12
                                    }
                                })

                            }


                            newFields.push({
                                type: 'spacer',
                            }
                            );


                            found = true;
                            if (i !== fields.length - 1) {

                                newFields.push(fields[i]);
                            }

                        } else {
                            newFields.push(fields[i]);
                        }

                    }


                    scope.setState({ fields: newFields })

                },
                renderItems: (data) => {
                    return Object.values(data.attributes).map((item) => {
                        return (
                            {
                                name: item.alias,
                                value: item.alias
                            }
                        )
                    })
                },
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            }

            , {
                type: 'button',
                text: 'New attribute',
                width: {
                    desktop: 12,
                    mobile: 12
                },

                onClick: (scope) => {
                    scope.props.handlePrompt({
                        text: 'Enter attribute alias',
                        callback: (value) => {
                            let fields = scope.state.fields;
                            let newFields = [];

                            let found = false;
                            for (let i = 0; i < fields.length; i++) {

                                if ((fields[i].type === 'title' && !found) || i === fields.length - 1) {

                                    if (i === fields.length - 1) {
                                        newFields.push(fields[i]);
                                    }

                                    newFields.push({
                                        type: 'title',
                                        text: value
                                    }
                                    );


                                    newFields.push({
                                        name: 'attributes.' + value + ".alias",
                                        label: 'Alias',
                                        type: 'text',
                                        multilang: false,
                                        width: {
                                            desktop: 6,
                                            mobile: 12
                                        }
                                    }
                                    );

                                    newFields.push({
                                        name: 'attributes.' + value + ".name",
                                        label: 'Name',
                                        type: 'text',

                                        width: {
                                            desktop: 6,
                                            mobile: 12
                                        }
                                    }
                                    );


                                    newFields.push({
                                        type: 'button',
                                        text: 'Add value',
                                        width: {
                                            desktop: 12,
                                            mobile: 12
                                        },

                                        onClick: (scope) => {
                                            let f = scope.state.fields;
                                            let nF = [];
                                            for (let j = 0; j < f.length; j++) {
                                                if (f[j].name === 'attributes.' + value + ".name") {


                                                    let k = 0;
                                                    for (let l = 0; l < f.length; l++) {
                                                        if (f[l].name && f[l].name.indexOf('attributes.' + value + ".values") !== -1) {
                                                            k++;
                                                        }
                                                    }

                                                    k /= 2;
                                                    console.log(k);

                                                    nF.push(f[j]);

                                                    nF.push({
                                                        name: 'attributes.' + value + ".values[" + (k) + "].value",
                                                        label: 'Item[' + (k) + "] Value",
                                                        type: 'text',
                                                        multilang: false,
                                                        width: {
                                                            desktop: 3,
                                                            mobile: 12
                                                        }
                                                    }

                                                    );

                                                    nF.push({
                                                        name: 'attributes.' + value + ".values[" + (k) + "].name",
                                                        label: 'Item[' + (k) + "] Name",
                                                        type: 'text',

                                                        width: {
                                                            desktop: 3,
                                                            mobile: 12
                                                        }
                                                    });

                                                    nF.push({
                                                        type: 'offset',
                                                        width: {
                                                            desktop: 12,
                                                            mobile: 12
                                                        }
                                                    })

                                                } else {
                                                    nF.push(f[j]);
                                                }
                                            }

                                            scope.setState({
                                                fields: nF
                                            })

                                        },
                                    })


                                    newFields.push({
                                        type: 'spacer',
                                    }
                                    );


                                    found = true;
                                    if (i !== fields.length - 1) {

                                        newFields.push(fields[i]);
                                    }

                                } else {
                                    newFields.push(fields[i]);
                                }

                            }


                            scope.setState({ fields: newFields })
                        }
                    })
                }

            },
            {
                type: 'spacer'
            }
        ],
        linkedFields: [

        ]
    },
    {
        title: 'Dostava',
        subtitle: 'Dodaj/Izmjeni dostavu',
        link: '/delivery-methods',
        collection: 'deliveryMethods',

        fields: [
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'name',
                label: 'Ime',
                type: 'text',

                width: {
                    desktop: 5,
                    mobile: 12
                }
            },
            {
                name: 'price',
                label: 'Cijena',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },
            {
                name: 'maxWeight',
                label: 'Max težina',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                name: 'maxVolume',
                label: 'Max kubikaža',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },


            {
                type: 'spacer'
            }
        ],
        linkedFields: []
    },

    {
        title: 'Slide',
        subtitle: 'Change slide',
        link: '/slides',
        collection: 'slides',

        fields: [

            {
                name: 'Image',
                label: 'Slika',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },



        ],
        linkedFields: []

    },
    {
        title: 'Banner',
        subtitle: 'Dodaj/Izmjeni baner',
        link: '/banners',
        collection: 'banners',
        multilang: false,
        fields: [

            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },
            {
                name: 'MobileImage',
                label: 'Mobile Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 4,
                    mobile: 12
                }
            },


            {
                type: 'spacer'
            },
            {
                name: 'Link',
                label: 'Link',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            
            {
                type: 'spacer'
            },

            {
                name: 'Side',
                label: 'Side banner',
                type: 'checkbox',
                width: {
                    desktop: 2,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Subscriber',
        subtitle: 'Dodaj/Izmjeni subscribera',
        link: '/subscribers',
        collection: 'subscribers',
        multilang: false,
        fields: [

    
            {
                name: 'email',
                label: 'E-mail',
                type: 'textarea',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },


    {
        title: 'Settings',
        subtitle: 'Change settings',
        link: '/settings',
        collection: 'settings',
        multilang: false,
        fields: [
            {
                name: 'footerText',
                label: 'Footer Text',
                type: 'text-multiline',

                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'address',
                label: 'Address',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'phone',
                label: 'Phone',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'email',
                label: 'Email',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },

    {
        title: 'Pop Up',
        subtitle: 'Change Pop Up',
        link: '/popup',
        collection: 'popup',
        multilang: false,
        fields: [
            {
                name: 'Title',
                label: 'Title',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Subtitle',
                label: 'Subtitle',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Text',
                label: 'Text',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 3,
                    mobile: 12
                }
            },
            {
                name: 'Image',
                label: 'Image',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'Background',
                label: 'Background',
                type: 'file',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Stranice',
        subtitle: 'Izmjena stranice',
        link: '/pages',
        collection: 'pages',
        multilang: false,
        fields: [

            {
                name: 'title',
                label: 'Naslov',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'alias',
                label: 'Alias',
                type: 'text',
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                type: 'spacer'
            },

            {
                name: 'content',
                label: 'Tekst',
                type: 'html',
                multilang: false,
                width: {
                    desktop: 12,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
    {
        title: 'Jedinice mjere',
        subtitle: 'Izmjena',
        link: '/units',
        collection: 'units',
        multilang: false,
        fields: [

            {
                name: 'name',
                label: 'Ime',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },
            {
                name: 'shortName',
                label: 'Skracenica',
                type: 'text',
                multilang: false,
                width: {
                    desktop: 6,
                    mobile: 12
                }
            },

            {
                type: 'spacer'
            },


        ],
        linkedFields: []

    },
]


let listPages = [
    {
        title: 'Stranice',
        subtitle: 'Lista stranica',
        link: '/pages',
        collection: 'pages',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: "alias",
                label: "Alias",
                type: "text",
            }, {
                name: "title",
                label: "Naslov",
                type: "text",
            },
        ],

    },
    {
        title: 'Jedinice mjere',
        subtitle: 'Lista',
        link: '/units',
        collection: 'units',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: "name",
                label: "Ime",
                type: "text",
            },
        ],

    },

    {
        title: 'Admins',
        subtitle: 'Lista admina',
        link: '/admins',
        collection: 'admins',
        searchFields: ['username', 'userRole'],
        itemFields: [
            {
                name: "username",
                label: "Username",
                type: "text",
            },
            {
                name: 'userRole',
                label: "Tip",

                type: "text"
            },
        ],
    },
    {
        title: 'Atributi',
        subtitle: 'Lista atributa',
        link: '/attributes',
        collection: 'attributes',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Name',
                label: "Naziv",

                type: "text"
            },
        ],
    }, {
        title: 'Novosti',
        subtitle: 'Lista novosti',
        link: '/news',
        haveImage: true,
        collection: 'news',
        searchFields: ['Alias', 'Title'],
        itemFields: [
            {
                name: 'Image',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Alias",
                type: "text",
            },
            {
                name: 'Title',
                label: "Naslov",

                type: "text"
            },
        ],
        sortOptions: [
            {
                name: 'Po naslovu',
                field: 'Title',
                sort: 1
            },
        ]
    }, {
        title: 'Proizvodi',
        subtitle: 'Lista proizvoda',
        link: '/products',
        haveImage: true,
        multilang: false,
        collection: 'products',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Images[0]',
                type: "image",
                disableSort: true
            }, {
                name: "Alias",
                label: "Šifra proizvoda",
                type: "text",
            },
            {
                name: 'Name',
                label: "Naziv",

                type: "text"
            },
            {
                name: "StockLevel",
                label: "Stanje",

                type: "number",
                allowEdit: true

            },
            {
                name: "package[0].mpc",
                label: "MPC",

                type: "price",
                allowEdit: true

            },
            /*{
                name: "package[0].vpc",
                label: "VPC",

                type: "price",
                allowEdit: true

            },*/
            {
                name: "discount",
                label: "Popust [%]",

                type: "number",
                allowEdit: true

            }

        ],
        sortOptions: [
            {
                name: 'Po imenu',
                field: 'Name.de',
                sort: 1
            },
            {
                name: 'MPC - ascending',
                field: 'price',
                sort: 1
            },
            {
                name: 'Price - descending',
                field: 'price',
                sort: -1
            },
            {
                name: 'Stock - ascending',
                field: 'StockLevel',
                sort: 1
            },
            {
                name: 'Stock - descending',
                field: 'StockLevel',
                sort: -1
            }
        ]
    },
    {
        title: 'Korisnici',
        subtitle: 'Lista korisnika',
        link: '/users',
        haveImage: false,
        collection: 'users',
        searchFields: ['Alias', 'User.Name', 'User.EMail', 'Alias'],
        itemFields: [
            /*{
                name: '_id',
                type: "text"
            }*/,
            {
                name: 'Alias',
                label: 'Redni br.',
                type: "text"
            },
            {
                name: 'UserRegion',
                label: 'Regija',
                type: "text"
            },

            {
                name: 'User.Name',
                label: 'Name',
                type: "text"
            },
            {
                name: 'User.EMail',
                label: 'EMail',
                type: "text"
            },
            {
                name: 'BillingAddress.City',
                label: 'Grad',
                type: "text"
            },

            {
                name: 'CustomerGroup',
                label: 'Korisnička grupa',
                type: "text"
            },

        ],
    }, {
        title: 'Kategorije',
        subtitle: 'Lista kategorija',
        link: '/categories',
        collection: 'categories',
        searchFields: ['Alias', 'Name'],
        itemFields: [
            {
                name: 'Alias',
                type: "text",
                label: "Alias"
            },
            {
                name: 'Breadcrumb',
                type: "text",
                label: "Breadcrumb"

            },
        ],

    }, {
        title: 'Dostave',
        subtitle: 'Lista dostava',
        link: '/delivery-methods',
        haveImage: false,
        collection: 'deliveryMethods',
        searchFields: ['alias', 'name'],
        itemFields: [
            {
                name: 'alias',
                type: "text",
                label: "Alias"

            },
            {
                name: 'name',
                type: "text",
                label: "Name"

            },
            {
                name: 'price',
                type: "price",
                label: "Price"

            },
        ],
    },
    {
        title: 'Slider na početnoj',
        subtitle: 'Lista slajdova',
        link: '/slides',
        haveImage: true,
        collection: 'slides',
        searchFields: [],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            },
        ],
    },
    {
        title: 'Baneri',
        subtitle: 'Lista banera',
        link: '/banners',
        haveImage: true,
        collection: 'banners',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            },
        ],
    },
    {
        title: 'Subscribers',
        subtitle: 'Lista',
        link: '/subscribers',
        collection: 'subscribers',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'email',
                type: "text"
            },
        ],
    },

    {
        title: 'Newsletters',
        subtitle: 'List of newsletters',
        link: '/newsletters',
        haveImage: true,
        collection: 'newsletters',
        searchFields: ['Title', 'Text'],
        itemFields: [
            {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Popup',
        subtitle: 'List of popup',
        link: '/popup',
        haveImage: true,
        collection: 'popup',
        searchFields: ['Title', 'Subtitle'],
        itemFields: [
            {
                name: 'Image',
                type: "image"
            }, {
                name: 'Title',
                type: "text"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'Kontakti',
        subtitle: 'Lista kontakata',
        link: '/contacts',
        collection: 'contacts',
        disableDelete: true,
        disableEdit: true,
        searchFields: ['EMail', 'Name'],
        itemFields: [
            {
                name: 'Name',
                label: 'Ime',
                type: "text"
            },
            {
                name: 'EMail',
                label: 'EMail',

                type: "text"
            }, {
                name: 'Subject',
                label: 'Naslov',

                type: "text"
            }, {
                name: 'Message',
                label: 'Poruka',

                type: "text"
            },

        ],
    },
    {
        title: 'Narudzbe',
        subtitle: 'Lista narudzbi',
        link: '/orders',
        collection: 'orders',
        disableDelete: true,
        searchFields: ['PayPalTransaction.transactions.invoice_number', 'BillingAddress.FirstName', 'BillingAddress.LastName', 'BillingAddress.Company'],
        itemFields: [
            {
                name: 'orderNumber',
                label: 'Br. narudžbe',
                type: "text"
            },
            {
                name: 'orderTime',
                label: 'Datum',

                type: "datetime",
                format: "DD.MM.YYYY"
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Firma ili ime',
                type: "text"
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'PJ / Prezime',

                type: "text"
            },
            {
                name: 'ShippingAddress.City',
                label: 'Grad',

                type: "text"
            },
            {
                name: 'DeliveryMethod.name',
                label: 'Dostava',

                type: "text"
            },
            {
                name: 'BillingMethod',
                label: 'Način plaćanja',

                type: "text"
            },

            {
                name: 'total',
                label: 'Ukupno',
                type: "price"
            },

            {
                name: 'Payed',
                label: 'Plaćeno',
                type: "price",
                allowEdit: true
            },

            {
                name: 'ToPay',
                label: 'Dug',
                type: "price"
            },
            {
                name: 'Status',
                label: 'Status',
                type: "text"
            },
            {
                name: 'timeToPay',
                label: 'Valua plaćanja',

                type: "datetime",
                format: "DD.MM.YYYY"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'User orders',
        subtitle: 'List of orders',
        link: '/user-orders/:uid',
        collection: 'orders',
        disableDelete: true,
        searchFields: ['PayPalTransaction.transactions.invoice_number', 'BillingAddress.FirstName', 'BillingAddress.LastName', 'BillingAddress.Company'],
        itemFields: [
            {
                name: 'orderNumber',
                label: 'Br. narudžbe',
                type: "text"
            },
            {
                name: 'orderTime',
                label: 'Datum',

                type: "datetime",
                format: "DD.MM.YYYY"
            },

            {
                name: 'ShippingAddress.FirstName',
                label: 'Firma ili ime',
                type: "text"
            },
            {
                name: 'ShippingAddress.LastName',
                label: 'PJ / Prezime',

                type: "text"
            },
            {
                name: 'ShippingAddress.City',
                label: 'Grad',

                type: "text"
            },
            {
                name: 'DeliveryMethod.name',
                label: 'Dostava',

                type: "text"
            },
            {
                name: 'BillingMethod',
                label: 'Način plaćanja',

                type: "text"
            },

            {
                name: 'total',
                label: 'Ukupno',
                type: "price"
            },

            {
                name: 'Payed',
                label: 'Plaćeno',
                type: "price",
                allowEdit: true
            },

            {
                name: 'ToPay',
                label: 'Dug',
                type: "price"
            },
            {
                name: 'Status',
                label: 'Status',
                type: "text"
            },
            {
                name: 'timeToPay',
                label: 'Valua plaćanja',

                type: "datetime",
                format: "DD.MM.YYYY"
            },



        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    }, {
        title: 'Brendovi',
        subtitle: 'Lista brendova',
        link: '/brands',
        collection: 'brands',
        searchFields: [],
        haveImage: true,
        itemFields: [

            {
                name: 'Image',
                label: 'Slika',
                type: "image",
                disableSort: true

            },
            {
                name: 'Name',
                label: 'Ime',
                type: "text"
            },

            {
                name: 'Position',
                label: 'Pozicija',
                allowEdit: true,
                type: "number"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },
    {
        title: 'Firme',
        subtitle: 'Lista firmi',
        link: '/companies',
        collection: 'companies',
        searchFields: [],
        haveImage: true,
        itemFields: [

            {
                name: 'Image',
                label: 'Slika',
                type: "image",
                disableSort: true

            },
            {
                name: 'Link',
                label: 'Link',
                type: "text"
            },
            {
                name: 'Position',
                label: 'Pozicija',
                allowEdit: true,
                type: "number"
            },

        ],
        sortOptions: [
            {
                name: 'Relevant',
                field: '_id',
                sort: -1
            }

        ]
    },

];


class Routes extends Component {

    componentDidMount() {
        /*const unlisten = history.listen((location, action) => {
            this.props.handleMenu(null);
        });
        */
    }

    render() {
        return (
            <Router >
                <div>

                    <GoogleMapScript API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />

                    <Switch className="react-switch">

                        {this.props.uData && this.props.uData.userRole == 'super' ?

                            <Route
                                path="/"
                                exact
                                render={(...renderProps) => (
                                    <HomePage {...renderProps} {...this.props} />
                                )}
                            />

                            :
                            !this.props.uData ?
                                <Route
                                    path="/"
                                    exact
                                    render={(...renderProps) => (
                                        <LoginPage {...renderProps} {...this.props} />
                                    )}
                                />

                                : null


                        }

                        <Route
                            path="/login"
                            exact
                            render={(...renderProps) => (
                                <LoginPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/order/:id"
                            exact
                            render={(...renderProps) => (
                                <Order {...renderProps} {...this.props} />
                            )}
                        />

                        {
                            listPages.map((page, idx) => {
                                //console.log(page.link, (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || (this.props.uData.userRole == 'special' && page.link == '/products') || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid')))))
                                if (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || page.link == '/user-orders/:uid' || (this.props.uData.userRole == 'special' && (page.link == '/products' || page.link == '/users' || page.link == '/priceList/:uid')) || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid')))) {

                                    return (

                                        <Route
                                            path={page.link}
                                            exact
                                            render={(...renderProps) => (
                                                <ListPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />



                                    )
                                }

                            })

                        }

                        {
                            listPages.map((page, idx) => {
                                //console.log(page.link, (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || (this.props.uData.userRole == 'special' && page.link == '/products') || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid')))))
                                if (this.props.uData && ((this.props.uData.userRole == 'special' && page.link == '/products') || (this.props.uData.userRole == 'simple' && page.link == '/users'))) {
                                    return (
                                        <Route
                                            path={'/'}
                                            exact
                                            render={(...renderProps) => (
                                                <ListPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />
                                    )
                                }
                            })
                        }

                        {
                            formPages.map((page, idx) => {
                                if (this.props.uData && (this.props.uData.userRole == 'super' || page.link == '/orders' || (this.props.uData.userRole == 'special' && (page.link == '/products' || page.link == '/users' || page.link == '/priceList/:uid')) || (this.props.uData.userRole == 'simple' && (page.link == '/users' || page.link == '/priceList/:uid'))))
                                    return (

                                        <Route
                                            path={`${page.link}/:id`}
                                            exact
                                            render={(...renderProps) => (
                                                <FormPage {...page} {...renderProps} {...this.props} />
                                            )}
                                        />



                                    )

                            })
                        }




                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;